function setNavigation() {
    var path = window.location.pathname;
    path = path.replace(/\/$/, "");
    path = decodeURIComponent(path);
    path = path.substring(path.lastIndexOf('/')+1, path.length);

    $(".menu__item").each(function () {
        var href = $("a", this).attr('href');
        
        href = href.substring(href.lastIndexOf('/'), href.length);

        if (path === href) {
            $(this).addClass('active');
        }
    });
};

function StopVideo() {
    var iframe = $("#youtube")[0].contentWindow;

    func = 'pauseVideo';
    iframe.postMessage('{"event":"command","func":"' + func + '","args":""}', '*');
    $(".video-popup iframe").attr('src', "");
}

function detectFaces(imgCanvas, detector) {
    var rects = detector.detect(imgCanvas);
    var facesLength = 0;
    for (var i = 0; i < rects.length; ++i) {
        if (facesLength >= 1) break;
        var coord = rects[i];
        faces = coord;
        console.log('Координаты лица:' + faces);
        facesLength++;
    }
}
    
//Определяем глаза
function detectEyes(currentCanvas, detector) {
    var rects = detector.detect(currentCanvas);
    var k = 0;//счетчик кол-ва глаз
    min = 5000; // min = 1;

    for (var i = 0; i < rects.length; ++i) {
        if (k > 1) break;
        var coord = rects[i];
        if ((coord[0] > faces[0] && coord[0] < faces[0] + faces[2]) && (coord[1] > faces[1] && coord[1] < faces[1] + faces[3])){
            //условия отрисовывания по большому глазу
            if (coord[1] < min){
                min = coord[1];
                coord[3] /=2;
                eyes = coord;
                One_eye = coord;
            }
            k++;
        }
    }
    console.log('Координаты глаз: ' + eyes);
    var addvalue = eyes[3] / 4;
    One_eye[1] -= addvalue;
    One_eye[3] += addvalue;
}

//Вырезаем два глаза
function getEyes(x, y, w, h) {

    var temp_canvas = document.getElementById('tempCanvas');
    temp_canvas.width = w;
    temp_canvas.height = h;
    temp_context = temp_canvas.getContext('2d');

    if (type == "photo") {
        temp_context.drawImage(canvas, x, y, w, h, 0, 0, w, h);
    } else {
        temp_context.drawImage(canvasVideo, x, y, w, h, 0, 0, w, h);
    }

    
    var vData = temp_canvas.toDataURL();

    localStorage.setItem("imgData", vData);
}    

function showPreload(callback) {
    $('.photo-image').removeClass('active');
    $('.photo-image').addClass('preload');
    callback();
}

//Распознаем и вырезаем
function DetectAndCut() {
    // alert('Процесс распознования может занять от 3 до 10 секунд!');

    if ($(window).width() < 1140) {

      $('.photo-resItem').removeClass('active');
      console.log(parseInt(Math.random() * ((photoLength-1)/2 - 1 + 1) + 1))
      $(".photo-resItem[data-num='" + parseInt(Math.random() * ((photoLength-1)/2 - 1 + 1) + 1) + "']").addClass('active');

      setTimeout(function(){ 
        showAnswer();
      }, 1000);

      return false;
    }

    var currentCanvas;

    if (type == "photo") {
      currentCanvas = canvas;
    } else {
      currentCanvas = canvasVideo;
    }

    mistake = 0;
    k = 4;
    detector = new objectdetect.detector(currentCanvas.width, currentCanvas.height, 1.2, classifier);
    detectFaces(currentCanvas, detector);
    if (faces.length === 0) {
        console.log('Не удалось обнаружить лицо');
        $('.photo-resItem').removeClass('active');
        $('.photo-error').addClass('active');
        showAnswer();
        return false;
    } else {
      // if ($(window).width() < 1140) {
      //   $('.photo-resItem').removeClass('active');
      //   $(".photo-resItem[data-num='" + parseInt(Math.random() * ((photoLength-1)/2 - 1 + 1) + 1) + "']").addClass('active');
      //   showAnswer();
      //   return false;
      // }
    }

    detector = new objectdetect.detector(currentCanvas.width, currentCanvas.height, 1.7, classifier1);
    detectEyes(currentCanvas, detector);
    if (eyes.length === 0) {
        console.log('Не удалось обнаружить глаз');
        $('.photo-resItem').removeClass('active');
        $('.photo-error').addClass('active');
        showAnswer();
        return false;
    } 
    getEyes(One_eye[0], One_eye[1], One_eye[2], One_eye[3]);

    for (var i = 0; i < photoLength; i++) {
        PhotoPath.push('images/stars-brows2/' + i + '.png');
    }
    getAnswers();
}

function setImg(par){

    var str;
    if (mistake < par) {
        mistake = par;
        $('.photo-resItem').removeClass('active');
        $(".photo-resItem[data-num='" + ((iter-iter%2)/2) + "']").addClass('active');
    }
    iter++;
    
}

function onComplete(data){
    var time = Date.now();
    var diffImage = new Image();
    answers.push(100 - parseFloat(data.misMatchPercentage));
    setImg(data.misMatchPercentage);
}

var getAnswers = function () {

    
    if ($(window).width() > 1139)  {
       answers = [];
        for (var i = 0; i < PhotoPath.length; i++) {
            resembleControl = resemble(localStorage.getItem("imgData")).compareTo(PhotoPath[i]).scaleToSameSize().ignoreAntialiasing().onComplete(onComplete);
        } 
    }
    
    showAnswer();
}

function showAnswer() {
    $('.photo-step--1').fadeOut('slow', function() {
        $('.photo-step--2').fadeIn('slow');
    });
    
}

var captureMe = function () {
    if (!videoStreamUrl) alert('Нажмите "разрешить" вверху окна')
    contextVideo.translate(canvasVideo.width, 0);
    contextVideo.scale(-1, 1);
    contextVideo.drawImage(video, 0, 0, video.width, video.height);

    var base64dataUrl = canvasVideo.toDataURL('image/png');
    contextVideo.setTransform(1, 0, 0, 1, 0, 0);
    var img = new Image();
    img.src = base64dataUrl;

    $('#takePhoto').removeClass('active');
    $('#readFortune').addClass('active');
    $('#takePhoto + span').removeClass('active');
    $('#readFortune + span').addClass('active');
}

function startVideo() {
    $('.photo-choice').removeClass('active');
    $('.photo-image').addClass('active');
    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
    window.URL.createObjectURL = window.URL.createObjectURL || window.URL.webkitCreateObjectURL || window.URL.mozCreateObjectURL || window.URL.msCreateObjectURL;

    navigator.getUserMedia({video: true}, function (stream) {
      videoStreamUrl = window.URL.createObjectURL(stream);
      video.src = videoStreamUrl;
      streamOn = stream.getVideoTracks()[0];
    }, function () {
      console.log('что-то не так с видеостримом или пользователь запретил его использовать');
    });

    $('#takePhoto').click(captureMe);
    $('#takePhoto + span').click(captureMe);
}

function trackTransforms() {

  
  var svg = document.createElementNS("http://www.w3.org/2000/svg",'svg');
  var xform = svg.createSVGMatrix();
  context.getTransform = function(){ return xform; };

  var savedTransforms = [];
  var save = context.save;
  context.save = function(){
      savedTransforms.push(xform.translate(0,0));
      return save.call(context);
  };

  var restore = context.restore;
  context.restore = function(){
    xform = savedTransforms.pop();
    return restore.call(context);
          };

  var scale = context.scale;
  context.scale = function(sx,sy){
    xform = xform.scaleNonUniform(sx,sy);
    return scale.call(context,sx,sy);
          };

  var rotate = context.rotate;
  context.rotate = function(radians){
      xform = xform.rotate(radians*180/Math.PI);
      return rotate.call(context,radians);
  };

  var translate = context.translate;
  context.translate = function(dx,dy){
      xform = xform.translate(dx,dy);
      return translate.call(context,dx,dy);
  };

  var transform = context.transform;
  context.transform = function(a,b,c,d,e,f){
      var m2 = svg.createSVGMatrix();
      m2.a=a; m2.b=b; m2.c=c; m2.d=d; m2.e=e; m2.f=f;
      xform = xform.multiply(m2);
      return transform.call(context,a,b,c,d,e,f);
  };

  var setTransform = context.setTransform;
  context.setTransform = function(a,b,c,d,e,f){
      xform.a = a;
      xform.b = b;
      xform.c = c;
      xform.d = d;
      xform.e = e;
      xform.f = f;
      return setTransform.call(context,a,b,c,d,e,f);
  };

  var pt  = svg.createSVGPoint();
  context.transformedPoint = function(x,y){
      pt.x=x; pt.y=y;
      return pt.matrixTransform(xform.inverse());
  }
}

function loadImage(src, orientation) {

    $('.photo-choice').removeClass('active');
    $('.photo-image').addClass('active');
    $('#canvasVideo').css('display', 'none')
    image = new Image();
    image.src = src;

    var canvasOffset=$("#canvas").offset();
    var offsetX=canvasOffset.left;
    var offsetY=canvasOffset.top;
    var canvasWidth=canvas.width;
    var canvasHeight=canvas.height;
    var isDragging=false;
    
    image.onload = start;

    function start() {
        trackTransforms();
        redraw(true);
        $('#takePhoto').removeClass('active');
        $('#takePhoto + span').removeClass('active');
        $('.photo-range').addClass('active');
        $('#readFortune').addClass('active');
        $('#readFortune + span').addClass('active');
        $('.photo-cromb').removeClass('changed');
        $('.photo-cromb').addClass('changed');
        $('#canvas').addClass('active');
    }

    function redraw(firstTime) {

      var p1 = context.transformedPoint(0,0);
      var p2 = context.transformedPoint(canvas.width,canvas.height);
      context.clearRect(p1.x,p1.y,p2.x-p1.x,p2.y-p1.y);

      context.save();
      context.setTransform(1,0,0,1,0,0);
      context.clearRect(0,0,canvas.width,canvas.height);
      context.restore();

      var tempWidth = canvas.width/2;
      var tempHeight = canvas.height/2;

      if ( firstTime ) {

        switch(orientation) {
            case 3:
                // 180° rotate left
                context.translate(canvas.width, canvas.height);
                context.rotate(Math.PI);
                break;
            case 6:
                // 90° rotate right
                context.rotate(0.5 * Math.PI);
                context.translate(0, -canvas.height);
                break;
            case 8:
                // 90° rotate left
                context.rotate(-0.5 * Math.PI);
                context.translate(-canvas.width, 0);
                break;
          }
      }

      
      context.clearRect(0,0,canvas.width,canvas.height);
      context.drawImage(image, 0, 0, image.width, image.height,    // source rectangle
        tempWidth/2 + (tempWidth - image.width * (tempHeight/image.height))/2 , tempHeight/2, image.width * (tempHeight/image.height), tempHeight);   

    }



    var lastX=canvas.width/2, lastY=canvas.height/2;

    var dragStart,dragged;

    canvas.addEventListener('mousedown',function(evt){
          document.body.style.mozUserSelect = document.body.style.webkitUserSelect = document.body.style.userSelect = 'none';
          lastX = evt.offsetX || (evt.pageX - canvas.offsetLeft);
          lastY = evt.offsetY || (evt.pageY - canvas.offsetTop);
          dragStart = context.transformedPoint(lastX,lastY);
          dragged = false;
    },false);

    canvas.addEventListener('mousemove',function(evt){
          lastX = evt.offsetX || (evt.pageX - canvas.offsetLeft);
          lastY = evt.offsetY || (evt.pageY - canvas.offsetTop);
          dragged = true;
          if (dragStart){
            var pt = context.transformedPoint(lastX,lastY);
            context.translate(pt.x-dragStart.x,pt.y-dragStart.y);
            redraw(false);
                }
    },false);

    canvas.addEventListener('mouseup',function(evt){
          dragStart = null;
          if (!dragged) zoomImg(evt.shiftKey ? -1 : 1 );
    },false);

    canvas.addEventListener('mouseout',function(evt){
          dragStart = null;
    },false);

    function onRangeChange(r,f) {
        var n,c,m;
        r.addEventListener("input",function(e){
            n=1;
            c=e.target.value;
            if(c!=m)f(e);
            m=c;
        });
        r.addEventListener("change", function(e){
            if(!n) f(e);
        });
    }

    var scaleFactor = 1.01;

    function zoomImg(clicks) {
        var pt = context.transformedPoint(lastX,lastY);
        context.translate(pt.x,pt.y);
        var factor = Math.pow(scaleFactor,clicks);
        var newWidth = (canvas.width - image.width*factor)/2;
        var newHeight = canvas.height/2;
        context.scale(factor,factor);
        context.translate(-pt.x,-pt.y);
        redraw(false);
    }

    var handleScroll = function(evt){
        var delta = evt.wheelDelta ? evt.wheelDelta/40 : evt.detail ? -evt.detail : 0;
        if (delta) zoomImg(delta);
        return evt.preventDefault() && false;
    };

    canvas.addEventListener('DOMMouseScroll',handleScroll,false);
    canvas.addEventListener('mousewheel',handleScroll,false);
}

function base64ToArrayBuffer (base64) {
    base64 = base64.replace(/^data\:([^\;]+)\;base64,/gmi, '');
    var binaryString = atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

function handleFileSelect(e) {
  // alert('handleFileSelect')
  // $('.photo-title').text('handleFileSelect')
    var file = e.target.files[0];
    // console.log(globOrintation);

    switch(globOrintation) {
      case 3:
          // 180° rotate left
          context.translate(-canvas.width, -canvas.height);
          context.rotate(Math.PI);
          break;
      case 6:
          // 90° rotate right
          context.rotate(-0.5 * Math.PI);
          context.translate(-canvas.width, 0);
          break;
      case 8:
          // 90° rotate left
          context.rotate(0.5 * Math.PI);
          context.translate(0, -canvas.height);
          break;
    }

    var reader = new FileReader();
    type = "photo";
    var exif = false;
    reader.onloadend = function(e) {
      // alert('onloadend')
      // $('.photo-title').text('onloadend')
        exif = EXIF.readFromBinaryFile(base64ToArrayBuffer(this.result));

        if (!exif) {
          globOrintation = 0;
          loadImage(e.target.result, 0);
        } else {
          globOrintation = exif.Orientation;
          loadImage(e.target.result, exif.Orientation);
        }
        
        $('.photo-cromb').addClass('active');
        $('.photo-title').removeClass('active');
        
        if ($(window).width() > 1139) {
          $('.photo-title:nth-child(2)').addClass('active');
        } else {
          $('.photo-title:nth-child(3)').addClass('active');
        }
    };
    reader.readAsDataURL(file);
}

function showTestResult() {

    $('.test-button').fadeOut();

    var answers = [];
    $('.test-qItem input:checked').each(function(){
        answers.push($(this).val());
    });

    var frequency = {};  
    var max = 0;  
    var result;   
    for(var v in answers) {
            frequency[answers[v]]=(frequency[answers[v]] || 0)+1; 
            if(frequency[answers[v]] > max) { 
                    max = frequency[answers[v]];  
                    result = answers[v];          
            }
    }
    $('.test-resItem:nth-child(' + parseInt(result) + ')').addClass('active');
}

function setEvents() {

    $('.product-mobSlider').slick({
        fade: true
    });

    var $productSlider = $('.product-slider').slick({
        fade: true,
        delay: -5
    });

    $('.product-close').click(function() {
        $('.product-wrapper').removeClass('active');
    });

    $('.product-wrapper').click(function (e) {
      if ($(e.target).is('.product-wrapper')){
        $('.product-wrapper').removeClass('active');
      }
    });

    $('.product-button, .product-item img, .product-msItem img').click(function() {
        var slide = parseInt($(this).attr('data-slide'))-1;
        if (slide > -1) {
            $productSlider.slick('slickGoTo', slide);
        }
       
        $('.product-wrapper').addClass('active');
    });

    $('.video-preview').click(function() {
        var video = $(this).attr('data-video');
        $(".video-popup iframe").attr('src', video);
        $('.video-wrapper').addClass('active');
    });

    $('.video-close').click(function() {
        $('.video-wrapper').removeClass('active');
        StopVideo();
    });

    $('.video-wrapper').click(function (e) {
        if ($(e.target).is('.video-wrapper')){
            $('.video-wrapper').removeClass('active');
        }
        StopVideo();
    });

    $('.product-button').hover(function() {
        var numberButton = $(this).attr('data-slide');

        $('.product-item').each(function( index ) {
          var number =  $( 'img', this ).attr('data-slide');
          if (number === numberButton) {
            $('img', this).toggleClass('hover');
          }
        });
    });

    var step = 1;
    $('.x-nexttest').click(function() {
        if (step === 1 || ($('.test-step[data-step=' + step + '] input:checked').length > 0)) {
            if (step === 5) {
                showTestResult();
            }
            $('.test-step[data-step=' + step + ']').fadeOut('slow', function() {
                $('.test-step[data-step=' + parseInt(step+1) + ']').fadeIn('slow', function() {
                    step = step+1;
                });
            });
        }
    });

    $('#toggle').on('click tap', function (e) {
        $(this).toggleClass('active');
        $(".menu__mobile").slideToggle();
    });

    $(".test-resItem p").mCustomScrollbar({theme:"minimal-dark"});
    $(".photo-resText").mCustomScrollbar({theme:"minimal-dark"});

    $(".photo-shareButton, .test-shareButton").click(function() { 

        if (window!=window.top) { 
            $('.menu__logo').hide();
            $('.ya-share2__item.ya-share2__item_service_vkontakte').css('display','none');
            $('.ya-share2__item.ya-share2__item_service_twitter').css('display','none');
            $('.ya-share2__item.ya-share2__item_service_facebook').css('display','none');

            var shareBlock = $(this).closest('.share-block');
            var href = $('.ya-share2', shareBlock).attr('data-url');

            FB.ui({
              method: 'share',
              href: href,
            }, function(response){});

            return false;
        }

        $(this).fadeOut(); 
    });

    if (($(window).width() > 1139) && (navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true ) || !!window.StyleMedia) //IF IE > 10
    {
      $(".photo-choice--r").removeClass('active');
      $(".photo-choice--l").addClass('active');
      $(".photo-choice--l").addClass('centered');
    }  else {
        $(".photo-choice--l").addClass('active');
        $(".photo-choice--r").addClass('active');
    }

    var $firstVideo = document.getElementById('firstVideo');
    var $loopedVideo = document.getElementById('loopedVideo');

    if ($(window).width() > 1139) {

      $('.main-desc').textillate({
           in: {
                effect: 'fadeIn'
            } 
      });

      if ($firstVideo) {
          $firstVideo.addEventListener('ended',myHandler,false);
          function myHandler(e) {
              console.log("firstVideo hidden");
              $firstVideo.classList.add("hidden");
          }

          if ( $firstVideo.readyState === 4 ) {
              $firstVideo.classList.add("active");
          } else {
              $firstVideo.onloadeddata = function () {
                  $firstVideo.classList.add("active");
              }
          }
      }

      if ( $loopedVideo.readyState === 4 ) {
          $loopedVideo.classList.add("active");
      } else {
          $loopedVideo.onloadeddata = function () {
              $loopedVideo.classList.add("active");
          }
      }
    } else {
        if ($firstVideo) { $('#firstVideo').attr('src', ''); }
        $('#loopedVideo').attr('src', '')
    }

   

    if (window!=window.top) { 
        $('.menu__logo').hide();
        $('.ya-share2__item.ya-share2__item_service_vkontakte').css('display','none');
        $('.ya-share2__item.ya-share2__item_service_twitter').css('display','none');
    }
}

if ($('#start-camera').length) {
        var canvas = $("#canvas");
        canvas = canvas.attr("width", 2*canvas.width()).attr("height", 2*canvas.height())[0];
        var context = canvas.getContext('2d');

        var canvasVideo = document.getElementById("canvasVideo");
        var contextVideo = canvasVideo.getContext('2d');

        var video = document.getElementById('video');
        var takePhoto = document.getElementById('takePhoto');
        var readFortune = document.getElementById('readFortune');
        var videoStreamUrl = false;
        var mistake = 0;
        var k = 4;
        var detector;
        var classifier = objectdetect.frontalface;
        var classifier1 = objectdetect.eye;
        var faces = new Array;
        var eyes = new Array;
        var One_eye = new Array;
        var PhotoPath = new Array;
        var answers = []; 
        var iter = 0;
        var streamOn = null;
        var photoLength = 12;
        var imageData;
        var copiedCanvas, copiedCanvasZoomed;
        var type;
        var zoom = 1;
        var globOrintation = 0;
    }

$( document ).ready(function() {
    setNavigation();
    setEvents();

    if ($('#start-camera').length) {
        $('#start-camera').click(function(event) {
            event.preventDefault();
            $('.photo-cromb').addClass('active');
            type = "video";
            $('#takePhoto + span').html('Сделать фото');
            $('.photo-cromb').removeClass('changed');
            $('.photo-cromb').addClass('changed');
            startVideo();
        });

        document.getElementById('load-photo').addEventListener('change', handleFileSelect, false);

        $('#readFortune, #readFortune + span').click(function(event) {

            event.preventDefault();
            
            showPreload(function() {
                setTimeout(function(){ 

                    DetectAndCut();

                    if (streamOn != null) {
                        streamOn.stop(); 
                        streamOn = null; 
                    }

                 }, 1000);
            });
        });
    }
});